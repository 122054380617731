import type DataObject from 'o365.modules.DataObject.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts';
import type { DataItemModel, ItemModel } from 'o365.modules.DataObject.Types.ts';
import type { FieldType } from 'o365.modules.DataObject.Fields.ts';
import utils from 'o365.modules.utils.js';

export interface Location {
    x: number;
    y: number;
}

export interface AreaSelection {
    start: Location;
    end: Location;
}

export default class SelectionControl<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _selectedUniqueKeys: Set<number|string> = new Set();
    private _selectedDataItems: DataItemModel<T>[] = [];
    private _selectionSave: boolean = false;

    private _cancelDataLoaded?: () => void;
    private _cancelDynamicDataLoaded?: () => void;

    selection?: AreaSelection;
    allRowsSelected?: boolean;
    selectAllLoading?: boolean;

    private _clearSelection: boolean = true;

    get selectedUniqueKeys() {
        return this._selectedUniqueKeys;
    }

    constructor(pOptions: { dataObject: DataObject<T> }) {
        this._dataObject = pOptions.dataObject ?? undefined;

        if (this._dataObject) {
            this._cancelDynamicDataLoaded = this._dataObject.on('DynamicDataLoaded', (clear: boolean, newData: DataItemModel<T>[]) => {
                this._clearSelection = clear;
                if (!this._clearSelection || this._selectionSave) {
                    this.selectRowsAfterLoad(newData);
                }
            })
            this._cancelDataLoaded = this._dataObject.on('DataLoaded', (data) => {
                if (this._clearSelection && !this._selectionSave) {
                    this.clearSelectionsArrays();
                } else {
                    this.selectRowsAfterLoad(data);
                }
            })
        }
    }

    destroy() {
        if (this._cancelDataLoaded) {
            this._cancelDataLoaded();
            this._cancelDataLoaded = undefined;
        }
        if (this._cancelDynamicDataLoaded) {
            this._cancelDynamicDataLoaded();
            this._cancelDynamicDataLoaded = undefined;
        }
    }

    onSelection(pIndex: number, pValue: boolean) {
        let item: DataItemModel<T>;

        if (pIndex < 0 && this._dataObject.batchDataEnabled) {
            const storageIndex = this._dataObject.batchData.getInversedIndex(pIndex);
            item = this._dataObject.batchData.storage.data[storageIndex] ?? null;
        } else {
            item = this._dataObject.storage.data[pIndex];
        }

        if (item == null) { return; }

        if (pValue) {
            this._selectedUniqueKeys.add(item[item.uniqueKeyField!]);
        } else {
            this._selectedUniqueKeys.delete(item[item.uniqueKeyField!]);
        }

        this.allRowsSelected = this.isAllRowsSelected();
    }

    private get isValidSelection() {
        return this.selection && this.selection.hasOwnProperty('start') && this.selection.hasOwnProperty('end');
    }

    get selectionSave() {
        return this._selectionSave;
    }

    get selectedDataItems() {
        return this._selectedDataItems;
    }

    clearSelectionsArrays() {
        this._selectedUniqueKeys.clear();
        this._selectedDataItems = [];
        this.allRowsSelected = false;
    }

    get areaselection() {
        return this.selection;
    }
    set areaselection(selection) {
        if (JSON.stringify(selection) !== JSON.stringify(this.selection)) {
            this.selection = selection;
        }
    }

    enableKeepSelectionAfterLoad() {
        this._selectionSave = true;
    }

    get selectedRows() {
        if (this._dataObject?.batchDataEnabled) {
            return [...this._dataObject.batchData.data.filter(row => row.isSelected), ...this._dataObject.data.filter(row => row?.isSelected)];
        } else {
            return this._dataObject?.data.filter(row => row?.isSelected);
        }
    }

    isAllRowsSelected() {
        const length = this._dataObject.batchDataEnabled
            ? this._dataObject.batchData.data.length
            : this._dataObject.data.length;

        if (this._dataObject.state.rowCount! > length) {
            return this._selectedUniqueKeys.size === this._dataObject.state.rowCount;
        } else {
            return this._selectedUniqueKeys.size === length;
        }
        
    }

    isSomeRowsSelected() {
        const length = this._dataObject.batchDataEnabled
            ? this._dataObject.batchData.data.length
            : this._dataObject.data.length;

        if (this._selectedUniqueKeys.size === 0) { return false; }
        if (this._dataObject.state.rowCount! > length) {
            if (this._selectedUniqueKeys.size < this._dataObject.state.rowCount!) { return true; }
            return false;
        }
        if (this._selectedUniqueKeys.size < length) { return true; }
        return false;
    }
    
    // Not in use
    isLocationInSelection(location: Location) {
        if (!location || !this.isValidSelection || this.selection == null) { return; }
        const startY = Math.min(this.selection.start.y, this.selection.end.y);
        const endY = Math.max(this.selection.start.y, this.selection.end.y);
        if (location.y < startY || endY < location.y) { return false; }

        const startX = Math.min(this.selection.start.x, this.selection.end.x);
        const endX = Math.max(this.selection.start.x, this.selection.end.x);
        if (location.x < startX || endX < location.x) { return false; }

        return true;
    }

    // Not in use
    yInSelection(y: number) {
        if (y == null || !this.isValidSelection || this.selection == null) { return; }
        const startY = Math.min(this.selection.start.y, this.selection.end.y);
        const endY = Math.max(this.selection.start.y, this.selection.end.y);
        return startY <= y && y <= endY;
    }

    // Not in use
    xInSelection(x: number) {
        if (x == null || !this.isValidSelection || this.selection == null) { return; }
        const startX = Math.min(this.selection.start.x, this.selection.end.x);
        const endX = Math.max(this.selection.start.x, this.selection.end.x);
        return startX <= x && x <= endX;
    }

    getSelection(columnArray: any[], rowData?: any[], pOptions?: {
        /**
         * Optional helper function used for manually resolving values. 
         * Must return a tuple of [field, value]
         * @example (pCol, pRow) => [pCol.name, pRow[pCol.name]]
         */
        valueResolve?: (pColumn: any, pRow: DataItemModel<T>) => [string, any]
    }) {

        if (this.isValidSelection) {
            const srcRows = rowData ?? this._dataObject?.data;
            if (!srcRows) { return []; }
            const rows = this.getRows(srcRows);
            const columns = this.getColumns(columnArray);
            if (rows == null || columns == null) { return; }
            const selected: Record<string, any>[] = [];
            rows.forEach((row, index) => {
                selected[index] = {};
                selected[index].index = row.index;
                columns.forEach(col => {
                    if (pOptions?.valueResolve) {
                        const [fieldName, fieldValue] = pOptions.valueResolve(col, row);
                        selected[index][fieldName] = fieldValue;
                    } else {
                        selected[index][col.name] = row[col.name];
                    }
                });
            });
            return selected;
        } else {
            return [];
        }
    }

    getSelectedRowsData(columnArray: SelectedColumn[]) {
        const selectedRows = this._dataObject?.data.filter((item) => item.isSelected);
        const selection: Record<string, any>[] = [];
        if (selectedRows) {
            selectedRows.forEach((row) => {
                let createdRow: Record<string, any> = {}
                columnArray.forEach((item) => {
                    const fieldName = typeof item === 'string' ? item : item.name;
                    if (!fieldName.startsWith('o365')) {
                        createdRow[fieldName] = row[fieldName];
                    }
                });
                selection.push(createdRow);
            });
        }
        return selection;
    }

    // Not in use
    getSelectedRowsDataRaw() {
        return this._dataObject?.data.filter((item) => item.isSelected);;
    }

    private checkColumnInclusion(column: SelectedColumn) {
        return !column.name.startsWith('o365') && !column.hide
    }

    private getColumnsForCopy(selectedRows: T[] | DataItemModel<T>[], gridColumns: SelectedColumn[]) {
        return selectedRows.length ?
            gridColumns.filter((column) => { return this.checkColumnInclusion(column) })
            : this.getColumns(gridColumns);
    }

    // Not in use
    findSelection(item: DataItemModel<T>) {
        const selectedUniqueKey = this._selectedUniqueKeys.has(item[item.uniqueKeyField!]);
        if (!selectedUniqueKey) return false;
        return true;
    }

    async selectAll(setSelectionTo = true) {
        this.allRowsSelected = !this.allRowsSelected;
        this.selectAllLoading = this.allRowsSelected === true ? true : false;
        let combinedCount = 0;
        const uniqueKeyField = this._dataObject.fields.uniqueField;
        this._dataObject.data.forEach((item) => {
            if (item == null) { return; }
            item.state.isSelected = setSelectionTo
            combinedCount++;
            this._selectedUniqueKeys.add(item[item.uniqueKeyField!])
        });
        if (this._dataObject.batchDataEnabled) {
            this._dataObject.batchData.data.forEach(item => {
                if (item == null) { return; }
                item.state.isSelected = setSelectionTo;
                combinedCount++;
                this._selectedUniqueKeys.add(item[item.uniqueKeyField!])
            });
        }
        if (!setSelectionTo) {
            this._selectedUniqueKeys.clear();
        } else if (this._dataObject.state.rowCount == null || combinedCount < this._dataObject.state.rowCount) {
            // Not all records are loaded, fetch all PrimKeys
            const records = await this._dataObject.recordSource.getAllUniqueKeys({ withSortOrder: true });
            this._dataObject.state.rowCount = records.length;
            records.forEach(record => this._selectedUniqueKeys.add(record[uniqueKeyField!]))
            this.selectAllLoading = false;
            import('o365.controls.alert.ts').then(alertModule => {
                alertModule.default(`Selected ${records.length} records`, 'info', {
                    autohide: true
                });
            });
        }
        this.selectAllLoading = false
    }

    unselectAll() {
        this.selectAll(false);
        this.allRowsSelected = false;
    }

    getRows(data: DataItemModel<T>[]) {
        if (this.selection && this.isValidSelection) {
            const rows = this.selection.end.y - this.selection.start.y >= 0
                ? data.slice(this.selection.start.y, this.selection.end.y + 1)
                : data.slice(this.selection.end.y, this.selection.start.y + 1);
            return rows;
        } else {
            return undefined;
        }
    }

    getColumns<T1 extends SelectedColumn>(data: T1[]) {
        if (this.selection && this.isValidSelection) {
            const columns = this.selection.end.x - this.selection.start.x >= 0
                ? data.slice(this.selection.start.x, this.selection.end.x + 1)
                : data.slice(this.selection.end.x, this.selection.start.x + 1);
            return columns?.filter(x => !x.hide);
        } else {
            return undefined;
        }
    }

    // Only for dataObject
    async getSelectedRows() {
        const options = { ...this._dataObject.recordSource.getOptions(), maxRecords: -1 };
        const allData: T[] | DataItemModel<T>[] = await this._dataObject.dataHandler.retrieve(options);

        if (this._selectedUniqueKeys.size === allData.length) {
            return allData;
        } else {
            return allData.filter(item => this._selectedUniqueKeys.has(item[item.uniqueKeyField]));
        }
    }

    async copySelection(withHeaders = false, gridColumns: SelectedColumn[], copyAsJson = false) {
        let selectedRows: T[] | DataItemModel<T>[] | null = null;
        if (this._selectedDataItems.length > 0) {
            selectedRows = this._selectedDataItems;
        }
        if (this._selectedUniqueKeys.size > 0 && selectedRows == null) {
            selectedRows = await this.getSelectedRows();
        } else {
            selectedRows = this._dataObject.data.filter(item => item && item.isSelected);
        }
        if (selectedRows == null) { return; }
        console.log(selectedRows.length)
        const columns = this.getColumnsForCopy(selectedRows, gridColumns);
        const rows = selectedRows.length ? selectedRows : this.getRows(this._dataObject.data);
        if (columns == null || rows == null) { return; }
        let result = '';
        const jsonCopy: Record<string, any>[] = [];
        if (columns && columns.length > 0 && withHeaders) {
            columns.forEach((col, colIndex) => {
                result += col.caption;
                if (colIndex < columns.length - 1) { result += '\t'; }
            });
            result += '\n';
        }
        rows.forEach((row, rowIndex) => {
            let jsonColumnData: Record<string, any> = {};
            columns.forEach((col, colIndex) => {
                if (col.unbound) {
                    if (col.getCopyValue) {
                        result += col.getCopyValue(row, 0) ?? '\t';
                        jsonColumnData[col.name] = col.getCopyValue(row, 0);
                    }
                } else {
                    const value = row[col.name];
                    if (value) {
                        let formatedValue = this.formatForCopyValue(value, col.type);;
                        jsonColumnData[col.name] = formatedValue;
                        result += formatedValue;
                    }
                }
                if (colIndex < columns.length - 1) { result += '\t'; }
            });

            jsonCopy.push(jsonColumnData);
            if (rowIndex < rows.length - 1) { result += '\n'; }
        });
        result = copyAsJson ? JSON.stringify(jsonCopy) : result;
        navigator.clipboard.writeText(result)
            .then(() => console.log("Success copy", result))
            .catch((error) => console.error("error", error));
    }

    private handleJsonPaste(text: string, columns: DataColumn[]) {
        type JsonPasteObject = {
            fields?: { name: string }[];
            data?: any[][]
        };
        let jsonObject: JsonPasteObject | null | Record<string, any>[] = null;
        try {
            jsonObject = JSON.parse(text);
            if (typeof jsonObject !== 'object' || jsonObject == null) { throw new Error('Invalid JSON string'); }
            const isJsonObject = (pObj: any): pObj is JsonPasteObject => {
                return pObj?.hasOwnProperty('data') && pObj?.hasOwnProperty('fields');
            };
            if (isJsonObject(jsonObject)) {
                const vJsonObject: Record<string, any>[] = [];
                const vFields = jsonObject["fields"]!;
                jsonObject["data"]!.forEach((row) => {
                    const vTmp: Record<string, any> = {};
                    row.forEach((val, index) => {
                        vTmp[vFields[index].name] = val;
                    })
                    vJsonObject.push(vTmp);
                })

                jsonObject = vJsonObject;
            }
            if (Array.isArray(jsonObject) && this._dataObject.batchDataEnabled && this._dataObject.batchData.data.length && this.selection == null) {
                jsonObject.forEach((pastedRow) => {
                    let existingBatchItem = this._dataObject.batchData.data.find((item) => {
                        return item.isEmpty;
                    });
                    if (existingBatchItem) {
                        Object.keys(existingBatchItem.item).forEach((item) => {
                            if (!columns.find((column) => column.field === item && column.editable && !column.hide)) return;
                            if (existingBatchItem && item && pastedRow[item]) {
                                (existingBatchItem[item] as any) = pastedRow[item];
                                existingBatchItem.disableSaving = true;
                            }
                        });
                    } else {
                        throw new Error('Could not find existing new record');
                    }
                });
            }
        } catch (error) {
            return false;
        }
        return jsonObject;
    }

    async pasteSelection(event: KeyboardEvent, columns: DataColumn[]) {
        try {
            const text = await window.navigator.clipboard.readText();
            if (this.handleJsonPaste(text, columns)) {
                // JSON paste successful, skip default paste
                return;
            }

            const rows = text.split(/\r/).filter(row => row.trim() !== '');
            // const rows = text.split(/\r?\n/).filter(row => row.trim() !== '');
            const parsedText = rows.map(row => row.split('\t'));

            // const parsedText = text.split('\r\n').map(row => row.split('\t'));
            const pasteSelection = this.determinePasteSelection(event);
            const disableAutoSaving = rows.length > 1;
            if (pasteSelection && pasteSelection.startAtIndex != null && pasteSelection.columnStartAtIndex != null && pasteSelection.startAtIndex < 0
                && this._dataObject.batchDataEnabled && this._dataObject.batchData.data.length) {
                parsedText.forEach((pastedRow, index) => {
                    let existingBatchItem = this._dataObject.batchData.data.find((item) => {
                        //return item.index === pasteSelection.startAtIndex! + index;
                        return this._dataObject.batchData.getInversedIndex(item.index) === this._dataObject.batchData.getInversedIndex(pasteSelection.startAtIndex!) + index;
                        // return this._dataObject.batchData.getInversedIndex(item.index) === pasteSelection.startAtIndex! + index;
                    });
                    if (existingBatchItem) {
                        if (disableAutoSaving) {
                            existingBatchItem.disableSaving = disableAutoSaving;
                        }
                        const filteredColumns = columns.filter((col, colIndex) => {
                            const isEditable = typeof col.editable === 'function'
                                ? (col.editable as ((pItem: DataItemModel<T>) => boolean))(existingBatchItem!)
                                : col.editable;
                            return colIndex >= pasteSelection.columnStartAtIndex! && !col.hide && isEditable;
                        });
                        let systemColumns = 0;
                        filteredColumns.forEach((col, colIndex) => {
                            if (existingBatchItem == null) { return; }
                            if (col.name.startsWith('o365')) { systemColumns++; }
                            if (col.editable && (pastedRow.length + systemColumns) > colIndex) {
                                (existingBatchItem[col.name] as any) = this.formatForPasteValue(pastedRow[colIndex - systemColumns], col.type);
                            }
                        });
                    }
                });
            } else {
                const selectedRows = this.getRows(this._dataObject.data);
                const selectedColumns = this.getColumns(columns);
                if (selectedRows == null || selectedColumns == null) { return; }
                let parsedColumnLength: number | undefined = undefined;
                parsedText.every((col) => {
                    if (typeof parsedColumnLength === 'undefined') {
                        parsedColumnLength = col.length; return true;
                    }
                    else {
                        return parsedColumnLength === col.length;
                    }
                });
                if (parsedText.length >= selectedRows.length && (parsedColumnLength && (parsedColumnLength >= selectedColumns.length))) {
                    selectedRows.forEach((row, rowIndex) => {
                        selectedColumns.forEach((col, colIndex) => {
                            if (col.editable) {
                                (row[col.name] as any) = this.formatForPasteValue(parsedText[rowIndex][colIndex], col.type);
                            }
                        });
                    });
                } else if (parsedColumnLength != null) {
                    selectedRows.forEach((row, rowIndex) => {
                        selectedColumns.forEach((col, colIndex) => {
                            if (col.editable) {
                                const repeatingRow = rowIndex - Math.floor(rowIndex / parsedText.length) * parsedText.length;
                                const repeatingCol = colIndex - Math.floor(colIndex / parsedColumnLength!) * parsedColumnLength!;
                                (row[col.name] as any) = this.formatForPasteValue(parsedText[repeatingRow][repeatingCol], col.type);
                            }
                        });
                    });
                }
            }
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
    private formatForCopyValue(value: any, colType: string) {
        let returnValue;
        if (value === null) {
            return "";
        }
        switch (colType) {
            case "time":
                returnValue = utils.formatDate(value, "HH:mm");
                break;
            case "number":
                returnValue = parseFloat(value);
                break;
            case "datetime":
                returnValue = utils.formatDate(value, "yyyy-MM-dd HH:mm:ss");
                break;
            case "boolean":
                if (value === "USANN" || value === "FALSE" || !value || value === 0 || value === "") {
                    returnValue = 0;
                } else {
                    returnValue = 1;
                }
                break;
            case "date":
                returnValue = utils.formatDate(value, "yyyy-MM-dd");
                break;
            case 'bit':
                returnValue = `${value}`;
                break;
            default:
                returnValue = value;
                // returnValue = value.replace(/\n/g, '').replace(/\r/g, '');
                break;
        }

        return returnValue !== null ? returnValue : "";
    }
    private formatForPasteValue(value: any, colType: string) {
        let returnValue;
        if (value === null) {
            return "";
        }
        switch (colType) {
            case "number":
                if (value) {
                    returnValue = (parseFloat(value.replace(",", ".").replace(/ /g, "")));
                } else {
                    returnValue = (null);
                }
                break;
            case "boolean":
                if (value === "USANN" || value === "FALSE" || !value || value === 0 || value === "0") {
                    returnValue = (false);
                } else {
                    returnValue = (true);
                }
                break;
            case "time":
                returnValue = (value === "" ? null : utils.formatDate(value, "HH:mm"));
                break;
            case "date":
                returnValue = (utils.date.parseDate(value));
                break;
            case "datetime":
                returnValue = utils.date.parseDate(value);
                break;
            default:
                returnValue = value.replace(new RegExp('^\\n'), '');
                // returnValue = (value ? value.replace(new RegExp(String.fromCharCode(10), 'g'), " ") : value);
                break;
        }

        return returnValue !== null ? returnValue : "";
    }

    private determinePasteSelection(event: KeyboardEvent) {
        const pasteSelection: {
            startAtIndex: number | null;
            columnStartAtIndex: number | null
        } = { startAtIndex: null, columnStartAtIndex: null };

        let rowElement: HTMLElement | null = (event.target as HTMLElement)?.closest('[data-o365-rowindex]');
        let colElement: HTMLElement | null = (event.target as HTMLElement)?.closest('[data-o365-colindex]');

        if (rowElement && colElement) {
            const rowIndex = rowElement.getAttribute('data-o365-rowindex');
            const columnIndex = colElement.getAttribute('data-o365-colindex');

            pasteSelection['startAtIndex'] = Number(rowIndex);
            pasteSelection['columnStartAtIndex'] = Number(columnIndex);
        } else {
            rowElement = (event.target as HTMLElement)?.closest('[data-o365-rowindex]');
            colElement = (event.target as HTMLElement)?.closest('[data-o365-colindex]');
            if (rowElement && colElement) {
                const rowIndex = rowElement.dataset.o365Rowindex;
                const columnIndex = colElement.dataset.o365Colindex;

                pasteSelection['startAtIndex'] = Number(rowIndex);
                pasteSelection['columnStartAtIndex'] = Number(columnIndex);
            }
        }

        return pasteSelection;
    }

    selectRowsAfterLoad(data: DataItemModel<T>[]) {
        this._selectedUniqueKeys.forEach(key => {
            const row = data.find(row => row[row.uniqueKeyField!] === key);
            if (row) {
                row.isSelected = true;
            }
        })
        this._selectedDataItems.forEach(row => this.selectedRows.push(row));
    }

    /**
     * Set selection in a range, pEnd cannot be greater than pStart
     * @param pValue value to set to
     * @param pStart range start
     * @param Pend range end
     */
    async selectRange(pValue: boolean, pStart: number, pEnd: number) {
        if (pStart > pEnd) { throw new TypeError('End cannot be greater than start in selection range'); }
        let hasMissingRowsInRange = false;
        for (let i = pStart; i <= pEnd; i++) {
            const item = this._dataObject.data[i];
            if (item == null) {
                hasMissingRowsInRange = true;
            } else {
                item.isSelected = pValue;
            }
        }
        if (hasMissingRowsInRange) {
            const fields = [{ name: 'PrimKey' }];
            this._dataObject.recordSource.appendSortByFields(fields);
            const data: string[] = (await this._dataObject.recordSource.retrieve({
                fields: fields,
                maxRecords: pEnd - pStart,
                skip: pStart,
            })).map(item => item.PrimKey);
            data.forEach(key => {
                const hasUniqueKey = this._selectedUniqueKeys.has(key);
                if (pValue && !hasUniqueKey) {
                    this._selectedUniqueKeys.add(key);
                } else if (!pValue && hasUniqueKey) {
                    this._selectedUniqueKeys.delete(key);
                }
            });
        }

        this.allRowsSelected = this.isAllRowsSelected();
    }

}

type SelectedColumn = DataColumn | {
    name: string;
    caption: string;
    type: FieldType;
    hide?: boolean;
    unbound?: boolean;
    getCopyValue?: (pColumn: DataColumn) => any;
};
